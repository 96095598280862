
require('normalize.css/normalize.css');
require('./styles/index.scss');

var $ = require('jquery');
var MenuAnchor = require('./js/menu-anchor')($);
var MicroModal = require('micromodal').default;
var Swiper = require('swiper');

function setSliderSize() {
  var sliderWrapper = document.getElementById('slider-wrapper');
  var sliderWrapperPos = document.getElementById('slider-wrapper').getBoundingClientRect().left;
  var windowWidth = document.body.clientWidth;
  sliderWrapper.style.cssText = `width: ${windowWidth - sliderWrapperPos -5}px`;
}

$(document).ready(function() {
  setTimeout(function() {
    setSliderSize();
    var mySwiper = new Swiper.default('.swiper-container', {
      setWrapperSize: true,
      speed: 400,
      spaceBetween: 32,
      grabCursor: true,
      initialSlide: 0,
      scrollbarSnapOnRelease: true,
      mousewheelControl: true,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '#slider-next'
      },
      autoplay: {
        delay: 5000
      },
      breakpoints: {
        620: {
          slidesPerView: 1
        },
        1200: {
          slidesPerView: 2
        }
      }
    });  
  }, 200);

  $('.js-nav').MenuAnchor({
    window: window.top,
    pluralId: 'section-',
    contentPostfix: '-content',
    headerH: $('#header').height() + 10,
    scroll: true
  });

  $(window).scroll(function() {
    var $header = $('#header');
    var scrollBreakpoint = $('#header').outerHeight();
    var scrollY = window.scrollY;

    if (scrollY >= scrollBreakpoint) {
      $header.addClass('is-fixed ');
    } else {
      $header.removeClass('is-fixed');
    }
  });

  $('body').on('click', '.js-scroll-next', function() {
    var $body = $('html, body');
    var top = $('#section-2-content').offset().top - 140;
    $body.stop().animate({scrollTop: top}, 500);
  });

  $('body').on('click', '.js-scroll-to-contacts', function(e) {
    e.preventDefault();
    var $body = $('html, body');
    var top = $('#section-5-content').offset().top - 100;
    $body.stop().animate({scrollTop: top}, 500);
  });

  $('body').on('click', '.js-menu-toggler', function() {
    $('#header').toggleClass('is-opened-menu');
    $('.js-overlay').toggleClass('is-visible');
  });

  $('body').on('click', '.js-overlay', function() {
    $('#header').removeClass('is-opened-menu');
    $('.js-overlay').removeClass('is-visible');
  });

  $('.js-open-job-modal').on('click', function () {
    MicroModal.show('job-modal');
  })

  MicroModal.init({
    onShow (modal) {
      $('body').addClass('is-modal-opened');
      $('html, #header').css('padding-right', scrollBarWidth() + 'px');
      $('.js-overlay').addClass('is-visible');
    },
    onClose (modal) {
      $('body').removeClass('is-modal-opened');
      $('html, #header').css('padding-right', 0);
      $('.js-overlay').removeClass('is-visible');
    }
  });

  $('.lang-switch--selected').on('click', function(event) {
    event.preventDefault();
    $(this).parent().toggleClass('is-open');
  });

  $('.lang-switch').on('mouseleave', function() {
    $(this).removeClass('is-open');
  });

  $('.js-lang').on('click', function(e) {
    window.location = window.location.origin + '/' + $(this).data('lang');
  });

  function scrollBarWidth () {
    var div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';

    document.body.appendChild(div);
    var scrollWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    return scrollWidth;
  };

  window.onresize = function(event) {
    setSliderSize();
  };
});
